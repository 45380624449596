import { CommonModule }     from '@angular/common';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule }         from '@angular/core';

import { CopyToClipboardDirective } from './copy-to-clipboard/copy-to-clipboard.component';
import { TooltipDirective }         from './tooltip.component';

@NgModule({
  declarations: [
    CopyToClipboardDirective,
    TooltipDirective
  ],
  entryComponents: [
    CopyToClipboardDirective
  ],
  exports: [
    // Third Party
    NgbTooltipModule,

    // Custom
    CopyToClipboardDirective,
    TooltipDirective
  ],
  imports: [
    // Angular
    CommonModule,

    // Third Party
    NgbTooltipModule
  ]
})
export class DirectivesModule {}
